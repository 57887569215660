var swiper = new Swiper('.main-swiper', {
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  //   grabCursor: true,
  //   effect: 'creative',
  //   creativeEffect: {
  //     prev: {
  //       shadow: true,
  //       translate: [0, 0, -400],
  //     },
  //     next: {
  //       translate: ['100%', 0, 0],
  //     },
  //   },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
})

new Swiper('.speakers-swiper', {
  slidesPerView: 4,
  spaceBetween: 30,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  loop: true,
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
})
